import { FC } from 'react'

import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '@store/rootReducer'

import { List, Divider } from '@mui/material'

import DrawerHeader from './components/DrawerHeader'
import ListItem from './components/ListItem'

import {
  Home,
  Assignment,
  NoteAdd,
  ContactSupport,
  Logout,
  Person2,
  LocalOffer
} from '@mui/icons-material'

import { StyledDrawer } from './Drawer.styles'

import { frontPageUrl, applicationUrl, applicationSpaRoutes } from '@constants'

const menuItems = [
  {
    itemKey: 'myOverview',
    icon: <Home />,
    href: applicationSpaRoutes.myOverview
  },
  {
    itemKey: 'myDocuments',
    icon: <Assignment />,
    href: applicationSpaRoutes.myDocuments
  },
  {
    itemKey: 'createDocument',
    icon: <NoteAdd />,
    href: applicationSpaRoutes.createDocument
  },
  {
    itemKey: 'support',
    icon: <ContactSupport />,
    onClick: () => { window.location.href = `${frontPageUrl}/support` }
  }
]

const userMenuItems = [
  {
    itemKey: 'myProfile',
    icon: <Person2 />,
    href: applicationSpaRoutes.profileEdit
  },
  {
    itemKey: 'referralFactory',
    icon: <LocalOffer />,
    href: applicationSpaRoutes.referralFactory
  },
  {
    itemKey: 'logout',
    icon: <Logout />,
    onClick: () => { window.location.href = `${applicationUrl}/user/logout` }
  },
]

const Drawer: FC = () => {
  const { isDrawerOpen } = useSelector((state: RootState) => ({ isDrawerOpen: state.app.isDrawerOpen }), shallowEqual)

  return (
    <StyledDrawer variant="permanent" open={isDrawerOpen}>
      <DrawerHeader />
      <Divider />
      <List>
        {menuItems.map((item) => <ListItem key={item.itemKey} {...item} />)}
      </List>
      <Divider />
      <List>
        {userMenuItems.map((item) => <ListItem key={item.itemKey} {...item} />)}
      </List>
    </StyledDrawer>
  )
}

export default Drawer
